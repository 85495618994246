






















































































































import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  computed,
  watch,
} from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import { AxiosResponse } from 'axios'

import userAccountRequest from '@/composables/userAccountRequest'
import { fetchLive } from '@/inc/utils'

import VInput from '@/components/form/VInput.vue'
import Message from '@/components/ui/Message.vue'

import SelectPhoneFlag, {
  codeMask,
} from '@/components/form/selectPhoneFlag.vue'
import { extend } from 'vee-validate'
import { regex, required, email } from 'vee-validate/dist/rules.umd.js'

extend('email', email)
extend('regex', regex)
extend('required', required)

import PhoneValidForm from '@/components/my/PhoneValidForm.vue'

export default defineComponent({
  name: 'validate-phone',
  components: {
    SelectPhoneFlag,
    VInput,
    Message,
  },
  props: {
    user: Object,
    model: {
      type: Object as () => Record<string, any>,
      required: false,
    },
  },

  setup(props, ctx) {
    /* eslint-disable */
    const i18n = ref<Record<string, any>>()
    const content = ref<Record<string, any>>()
    /* eslint-enable */

    const formName = 'myUserForm'
    const state = useState(['connection'])
    const phoneCode = ref('+32')
    const phoneCountryRegex = ref('|(4)[0-9]{8}')
    const phonePlaceHolder = ref('499123456')

    const { chrome } = useState(['chrome'])
    const { $i18n, $route, $router } = ctx.root
    const firstSend = ref(true)
    const isSending = ref(false)
    const phoneNumber = ref('')
    const newPhoneNumber = ref('')
    const userNumber = `+${$route.query.Phone}`
    const countryDigits = (userNumber as string).match(
      // eslint-disable-next-line max-len
      /(?:\+|^00)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/
    )

    let lastDigits = (userNumber as string).substring(countryDigits![0].length)
    lastDigits = ctx.root.$options?.filters?.VMask(
      lastDigits,
      codeMask[countryDigits![0]]
    )

    const formatedNumber = ref(`${countryDigits![0]} ${lastDigits}`)
    const smsForm = ref(false)

    const params = {
      Token: $route.query.Token,
    }

    ctx.root.$on(
      `phone:flag:updated:${formName}`,
      (emitted: { phoneCode: string; placeHolder: string; regex: string }) => {
        state.connection.value.personalData.phoneCode = emitted.phoneCode
        phoneCode.value = emitted.phoneCode
        phonePlaceHolder.value = emitted.placeHolder
        phoneCountryRegex.value = emitted.regex
      }
    )

    const resendSms = async () => {
      isSending.value = true
      smsForm.value = true
      const { error } = await userAccountRequest(
        'users/activate/sendSms',
        'post',
        { Type: 'sendSms', Phone: userNumber },
        { params }
      )
      setTimeout(() => {
        firstSend.value = false
        isSending.value = false
      }, 60000)
    }

    const addNewNumber = async () => {
      isSending.value = true
      smsForm.value = true
      const { error } = await userAccountRequest(
        'users/activate/sendSms',
        'post',
        { Type: 'sendSms', Phone: `${phoneCode.value + phoneNumber.value}` },
        { params }
      )
      setTimeout(() => {
        firstSend.value = false
        isSending.value = false
      }, 60000)
    }

    watch(phoneNumber, newNumber => {
      if (newNumber) {
        newPhoneNumber.value = phoneCode.value + newNumber
      } else {
        newPhoneNumber.value = userNumber
      }
    })
    const phoneError = computed(() => {
      if (!phoneNumber.value) {
        return false
      }
      const regex = new RegExp(`^(${phoneCountryRegex.value})$`)
      const isValid = regex.test(phoneNumber.value)

      return isValid
    })

    watch(phoneError, newValue => {
      const bool = !newValue
      ctx.root.$emit('error:phone', Boolean(bool))
    })

    onBeforeMount(async () => {
      if (!$route.query.Token) {
        $router.push({
          name: 'MySignin',
          params: { lang: $i18n.locale },
        })
      }

      // onMounted(async () => {
      //   const { error } = await userAccountRequest(
      //     'users/activate/sendSms',
      //     'post',
      //     { Type: 'sendSms', Phone: `+${userNumber}` },
      //     { params }
      //   )

      //   setTimeout(() => {
      //     firstSend.value = false
      //   }, 60000)
      // })

      const { route: createRoute } = $router.resolve('/create')
      const res: AxiosResponse = await fetchLive(createRoute)

      content.value = res.data.content || {}
      i18n.value = res.data.i18n || {}
    })

    return {
      mYi18n: useState('my', ['i18n'])?.i18n,
      content,
      i18n,
      chrome,
      formName,
      phoneCode,
      phonePlaceHolder,
      phoneCountryRegex,
      formatedNumber,
      smsForm,
      PhoneValidForm,
      firstSend,
      resendSms,
      isSending,
      phoneNumber,
      newPhoneNumber,
      userNumber,
      phoneError,
      addNewNumber,
    }
  },
})
