






































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  toRef,
  watch,
} from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import { AxiosResponse } from 'axios'
import VueRecaptcha from 'vue-recaptcha'

import userAccountRequest from '@/composables/userAccountRequest'
import { passwordRegex } from '@/inc/app.config'
import { fetchLive } from '@/inc/utils'
import * as sso from '@/composables/sso'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import VInput from '@/components/form/VInput.vue'
import Message from '@/components/ui/Message.vue'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'my-password',
  components: {
    FeedbackMessage,
    VInput,
    VueRecaptcha,
    Message,
    CustomLink,
  },
  props: {
    user: Object,
  },

  setup(props, ctx) {
    /* eslint-disable */
    const i18n = ref<Record<string, any>>()
    const content = ref<Record<string, any>>()
    /* eslint-enable */

    const { chrome } = useState(['chrome'])
    const { $i18n, $route, $router } = ctx.root

    const errorMessage = ref('')
    const hasValue = ref(false)
    const isLoading = ref(false)
    const isPasswordMatching = ref(false)
    const isPasswordValid = ref(false)
    const isReset = ref(false)
    const password = ref('')
    const passwordConfirmation = ref('')
    const recaptcha = ref('')
    const recaptchaKey = ref(chrome.value.data?.recaptcha)
    const resetConfirmed = ref(false)

    let smsValidate = false

    if ($route.query.SmsValidate) {
      smsValidate = true
    }

    watch(toRef(chrome.value, 'data'), newValue => {
      recaptchaKey.value = newValue.recaptcha
    })

    onBeforeMount(async () => {
      if (!$route.query.Token) {
        $router.push({
          name: 'CommuneSignin',
          params: { lang: $i18n.locale },
        })
      }

      isReset.value = $route.name === 'CommuneResetPassword'
      const { route: createRoute } = $router.resolve('/create')
      const res: AxiosResponse = await fetchLive(createRoute)

      content.value = res.data.content || {}
      i18n.value = res.data.i18n || {}
    })

    const signIn = () => {
      sso.authorize()
    }

    const isReady = computed(() => {
      if (isReset.value) {
        return isPasswordValid.value && isPasswordMatching.value
      }

      return (
        isPasswordValid.value && isPasswordMatching.value && recaptcha.value
      )
    })

    const checkPassword = () => {
      hasValue.value = true
      isPasswordValid.value = passwordRegex.test(password.value)
    }

    const checkMatch = () => {
      isPasswordMatching.value = password.value === passwordConfirmation.value
    }

    const onSubmit = async () => {
      const path = isReset.value ? 'users/reset' : 'town/users/activate'
      const params = {
        Token: $route.query.Token,
      }
      isLoading.value = true
      resetConfirmed.value = false
      errorMessage.value = ''

      if (!isReset.value) {
        params['g-recaptcha-response'] = recaptcha.value
      }

      const { error } = await userAccountRequest(
        path,
        'post',
        { Password: password.value },
        { params }
      )

      if (error) {
        isLoading.value = false
        errorMessage.value = error?.response?.data?.message

        return
      }

      const name = 'CommuneSuccess'
      isLoading.value = false

      if (isReset.value) {
        resetConfirmed.value = true
      } else {
        $router.push({ name })
      }
    }

    const setRecaptcha = res => {
      recaptcha.value = res
    }

    return {
      recaptcha,
      mYi18n: useState('my', ['i18n'])?.i18n,
      checkMatch,
      checkPassword,
      content,
      errorMessage,
      hasValue,
      i18n,
      isLoading,
      isPasswordMatching,
      isPasswordValid,
      isReady,
      isReset,
      onSubmit,
      password,
      passwordConfirmation,
      recaptchaKey,
      resetConfirmed,
      setRecaptcha,
      signIn,
      chrome,
      smsValidate,
    }
  },
})
