var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-password wrapper m-l"},[(_vm.i18n && _vm.i18n.finalise)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxxs-20 col-s-16"},[(_vm.i18n.finalise.title)?_c('h1',{staticClass:"mb-m"},[_vm._v(" "+_vm._s(_vm.i18n.finalise.title)+" ")]):_vm._e(),(!_vm.smsForm)?_c('div',[(_vm.i18n.finalise.subtitle || _vm.i18n.finalisePassword.subtitle)?_c('p',{staticClass:"h3 password-subtitle"},[_vm._v(" "+_vm._s(_vm.i18n.finalise.subtitle)+" ")]):_vm._e(),_c('p',{staticClass:"password-summary"},[_vm._v(" "+_vm._s(_vm.i18n.finalise.fieldsPhone.intro)+" ")]),_c('p',{staticClass:"password-summary"},[_vm._v(" "+_vm._s(_vm.i18n.finalise.fieldsPhone.knownNumber)+" "),_c('strong',{staticStyle:{"color":"#e84e0f"}},[_vm._v(" "+_vm._s(("" + _vm.formatedNumber)))])]),_c('g-action',{staticClass:"submit",attrs:{"content":{
            tag: 'button',
            label: _vm.i18n.finalise.buttonPhone.label,
            icon: 'arrow',
          },"type":"submit"},on:{"on-click":_vm.resendSms}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.finalise.fieldsPhone.notValidNumber)}}),_c('span',{staticClass:"col-xxxs-20 col-s-13 mb-s mt-xs",staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"row bottom-xxxs"},[_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.i18n.finalise.fieldsPhone.numberLabel)+" * ")]),_c('div',{staticClass:"phone"},[_c('SelectPhoneFlag',{attrs:{"formName":_vm.formName,"selectedPhoneCode":_vm.phoneCode}}),_c('ValidationProvider',{attrs:{"name":"phone","rules":{
                  regex: ("^(" + _vm.phoneCountryRegex + ")$"),
                  required: false,
                },"custom-messages":{
                  regex: _vm.i18n.personnal.phoneError,
                  required: _vm.i18n.personnal.phoneError,
                },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var validate = ref.validate;
return [_c('v-input',{attrs:{"errors":errors,"required":true,"id":"gsm-personal-phone","maxlength":10,"placeholder":("" + _vm.phonePlaceHolder),"pattern":("^(" + _vm.phoneCountryRegex + ")$")},on:{"input":validate},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,false,4008166119)}),_c('g-action',{staticClass:"content__btn action--btn--full--link--icon--arrows-large-right",attrs:{"disabled":!_vm.phoneError,"content":{
                  tag: 'button',
                  label: _vm.i18n.finalise.buttonPhone.validate,
                  icon: 'arrow',
                },"type":"submit"},on:{"on-click":_vm.addNewNumber}})],1)])]),(!_vm.isSending && !_vm.firstSend)?_c('p',{staticClass:"resend-sms",domProps:{"innerHTML":_vm._s(
            ((_vm.i18n.finalise.fieldsPhone.troubleshoot) + "<br>" + (_vm.i18n.finalise.fieldsPhone.resend))
          )},on:{"click":_vm.resendSms}}):_vm._e(),(_vm.isSending)?_c('p',{staticClass:"resend-sms",staticStyle:{"color":"#e84e0f"}},[_vm._v(" "+_vm._s(_vm.i18n.validationPhone.form.isSend)+" ")]):_vm._e()],1):_vm._e(),(_vm.smsForm)?_c('div',[_c('p',{staticClass:"fatline mb-m",domProps:{"innerHTML":_vm._s(("" + (_vm.i18n.finalise.fieldsPhone.typeNumber)))}}),_c(_vm.PhoneValidForm,_vm._b({tag:"component"},'component',{
            isFirstSend: _vm.firstSend,
            phoneNumber: _vm.newPhoneNumber ? _vm.newPhoneNumber : _vm.userNumber,
            parentInputStyle: { border: 'none' },
          },false))],1):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }